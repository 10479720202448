<template>
  <!-- 代购单列表 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1 wtl_btnList" ref="btnList">
      <div class="btnList1 wtl_btnList1">
        <el-button class="itemBtn btnColor" @click="addAndmodify(0)"
          >新增</el-button
        >
        <el-button class="itemBtn btnColor" @click="addAndmodify(1)"
          >修改</el-button
        >
        <el-button class="itemBtn btnColor" @click="cancelBtn"
          >取消订单</el-button
        >
        <el-button class="itemBtn btnColor" @click="cancelBtns"
          >审核取消</el-button
        >
        <!-- <el-button class="itemBtn btnColor" @click="details"
          >查看详情</el-button> -->
        <el-button class="itemBtn btnColor" @click="fuKuan">收款确认</el-button>
        <el-button class="itemBtn btnColor" @click="orderTuiKuan"
          >订单退款</el-button
        >
        <el-button class="itemBtn btnColor wtl_mainBtns2" @click="caigouBtn"
          >采购</el-button
        >
        <el-button class="itemBtn btnColor" @click="daigouGenZong"
          >代购跟踪</el-button
        >
        <el-button class="itemBtn btnColor" @click="fenPeiKeFu"
          >分配客服</el-button
        >
        <el-button class="itemBtn btnColor" @click="myModel"
          >列表设置</el-button
        >
        <el-dropdown @command="handleCommand_dc">
          <el-button class="dropdownBtn">
            导出
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">导出</el-dropdown-item>
            <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
            <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="singleinpbtn wtl_queryBoxNew">
        <el-button class="seniorbut" @click="queryBtn_ok" icon="el-icon-search"
          >查询</el-button
        >
        <el-tooltip content="查询项恢复初始状态" placement="top">
          <div class="condition" @click="queryBtn_cz">清除</div>
        </el-tooltip>
        <el-tooltip content="查询更多条件" placement="top">
          <div class="condition" @click="more">{{ MoreConditions }}</div>
        </el-tooltip>
      </div>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <div class="singleinp wtlTimeBox">
            <div class="singleinpleft wtlTimeLabel">创建时间:</div>
            <div class="wtl_timeStyle">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.startCreateTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
              <div>至</div>
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.endCreateTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div>
          <!-- <div class="singleinp">
            <div class="singleinpleft">起始付款时间:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.startPayTime"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">结束付款时间:</div>
            <div class="singleinpright">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.endPayTime"
                type="date"
                placeholder="选择日期"
                @change="queryBtn_ok"
              ></el-date-picker>
            </div>
          </div> -->
          <div class="singleinp">
            <div class="singleinpleft">代购订单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.dgOrderNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">快递单号:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model.trim="queryData.courierNumber"
                placeholder="请输入内容"
                clearable
                @keyup.enter.native="queryBtn_ok"
              ></el-input>
            </div>
          </div>

          <div class="singleinp">
            <div class="singleinpleft">会员号:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 1)"
                v-model="queryData.memberId"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembers"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.memberId"
                  :value="item.memberId + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">会员名称:</div>
            <div class="singleinpright">
              <el-select
                class="inputs"
                @change="huiayu($event, 2)"
                v-model="queryData.memberName"
                placeholder="请选择会员"
                clearable
                filterable
                remote
                reserve-keyword
                :remote-method="getMembersName"
                value-key="memberId"
              >
                <el-option
                  v-for="(item, index) in memberList"
                  :label="item.nickname"
                  :value="item.nickname + ''"
                  :key="index"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">代购状态:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.orderType"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in orderTypeList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">订单类型:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.orderForm"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in orderFormList"
                  :key="item.statusVal"
                  :label="item.showName"
                  :value="item.statusVal"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- <div class="singleinp">
            <div class="singleinpleft">拆箱服务:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.unpackingService"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in unpackingServiceList"
                  :key="item.itemServiceId"
                  :label="item.title + '(' + item.unitPrice + ')'"
                  :value="item.itemServiceId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">是否敏感货:</div>
            <div class="singleinpright">
              <el-select
                class="queryInput"
                v-model="queryData.isSensitive"
                filterable
                placeholder="请选择"
                clearable
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="item in isSensitiveList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val"
                ></el-option>
              </el-select>
            </div>
          </div> -->
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        @row-click="rowclick"
        show-summary
        :summary-method="getSummaries"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        ></el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        ></el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            :width="item.width"
            sortable
            show-overflow-tooltip
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          ></el-table-column>
        </template>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <!-- 自定义表头排序 -->
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 弹窗 -->
    <div>
      <!-- 新建采购单 -->
      <el-dialog
        title="新建采购单"
        :visible.sync="showCaiGou"
        append-to-body
        width="40%"
        center
        @close="closeCaiGou"
      >
        <div>
          <el-form
            label-position="left"
            label-width="100px"
            :model="caiGouFormData"
          >
            <el-form-item label="购买订单号:">
              <el-input
                placeholder="请输入"
                v-model="caiGouFormData.buyNumber"
              ></el-input>
            </el-form-item>
            <el-form-item label="代购订单号:">
              <el-input
                placeholder="请输入"
                v-model="caiGouFormData.dgOrderNumber"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="createCaigou">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 收款确认 -->
      <el-dialog
        title="收款确认"
        :visible.sync="showFuKuan"
        append-to-body
        width="40%"
        center
        @close="closeFuKuan"
      >
        <div class="addForm">
          <el-form
            label-position="right"
            label-width="100px"
            :model="PayMoneyData"
            style="width: 100%"
          >
            <el-form-item label="收款代购单:">
              <el-input
                type="textarea"
                v-model="PayMoneyData.purchaseOrderNums"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="金额合计:">
              <el-input
                disabled
                placeholder=""
                v-model="PayMoneyData.totals"
              ></el-input>
            </el-form-item>
            <el-form-item label="收款账户:">
              <el-select
                v-model="PayMoneyData.financeAssetaccountId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in zijingList"
                  :key="item.accountId"
                  :label="item.accountName"
                  :value="item.accountId + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="收款时间:">
              <el-date-picker
                v-model="PayMoneyData.payTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="图片:">
              <el-upload
                :action="uploadUrl"
                :http-request="uploadSectionImg"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="fileListImg"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showFuKuan = false">取消</el-button>
          <el-button class="btnsColor" @click="confirmPaymen">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 取消订单审核 -->
      <el-dialog
        title="取消订单审核"
        :visible.sync="cancelOrders"
        append-to-body
        width="40%"
        center
        @close="closeCancelOrder"
      >
        <div class="addForm">
          <el-form
            label-position="right"
            label-width="100px"
            :model="shenHeData"
            style="width: 100%"
          >
            <el-form-item label="申请原因:">
              <el-input
                readonly
                type="text"
                v-model="shenHeData.cancelReason"
              ></el-input>
            </el-form-item>
            <el-form-item label="审核意见:">
              <el-select
                v-model="shenHeData.cancelStatus"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cancelStatusList"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="拒绝原因:"
              v-if="shenHeData.cancelStatus == 'bu_tong_yi:cancel_status'"
            >
              <el-input
                type="textarea"
                v-model="shenHeData.refuseReason"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelOrders = false">取消</el-button>
          <el-button class="btnsColor" @click="confirmCancelDia"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 取消订单 -->
      <el-dialog
        title="取消订单审核"
        :visible.sync="cancelOrder_1"
        append-to-body
        width="40%"
        center
        @close="closeCancelOrder_1"
      >
        <div class="addForm">
          <el-form
            label-position="right"
            label-width="100px"
            :model="shenHeData_1"
            style="width: 100%"
          >
            <el-form-item label="已选代购订单:">
              <el-input
                readonly
                type="textarea"
                v-model="shenHeData_1.dgNums"
              ></el-input>
            </el-form-item>
            <el-form-item label="取消原因:">
              <el-select
                v-model="shenHeData_1.reason"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in cancel_order_reason_list"
                  :key="item.key"
                  :label="item.key"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="cancelOrder_1 = false">取消</el-button>
          <el-button class="btnsColor" @click="confirmCancelDia_1"
            >确 定</el-button
          >
        </div>
      </el-dialog>

      <!-- 分配客服 -->
      <el-dialog
        title="分配客服"
        :visible.sync="showFenPeiKF"
        append-to-body
        width="40%"
        center
        @close="closeFPKF"
      >
        <div class="addForm">
          <el-form
            label-position="right"
            label-width="100px"
            :model="FPKFData"
            style="width: 100%"
          >
            <el-form-item label="已选代购单:">
              <el-input
                disabled
                type="textarea"
                v-model="FPKFData.daiGouOrders"
              ></el-input>
            </el-form-item>
            <el-form-item label="跟单客服:">
              <el-select
                v-model="FPKFData.kfId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in KfContactList"
                  :key="item.contactId"
                  :label="item.name"
                  :value="item.contactId + ''"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="showFenPeiKF = false">取消</el-button>
          <el-button class="btnsColor" @click="confirmCancelDiaKF"
            >确 定</el-button
          >
        </div>
      </el-dialog>
    </div>

    <addCustomerTarck
      :showDia1="showDia1"
      :documentNumbers="documentNumbers"
      :documentStatus="documentStatus"
      :isdisabledStatus="true"
      @confirmAdd="confirmAdd"
      @closeDia1="closeDia1"
    ></addCustomerTarck>
  </div>
</template>

<script>
import sorttable from "@/components/sortTemplate.vue";
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import addCustomerTarck from "@/components/CustomerServiceTrackingNew.vue";

// import { axios_file } from "@/assets/js/http_axios";
export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
    addCustomerTarck,
  },
  data() {
    return {
      isActive: true, //输入框绑定动态class
      judge: false, //输入框绑定动态class
      MoreConditions: "更多条件", //输入框绑定动态class
      queryData: {
        agentId: "", //归属代理商id
        courierNumber: "", //快递单号
        dgOrderNumber: "", //代购订单号
        startCreateTime: "", //起始创建时间
        endCreateTime: "", //结束创建时间
        startPayTime: "", //起始付款时间
        endPayTime: "", //结束付款时间
        isSensitive: "", //是否敏感 1=敏感 0=非敏感
        memberId: "", //会员号
        memberName: "", //会员名称
        orderForm: "", //订单类型
        orderType: "", //代购状态
        unpackingService: "", //拆箱服务
      },
      isSensitiveList: [
        {
          val: 0,
          name: "非敏感",
        },
        {
          val: 1,
          name: "敏感",
        },
      ],
      caiGouFormData: {
        buyNumber: "",
        dgOrderNumber: "",
      },
      // ======公用组件==========
      pageNum: 1, //页码
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      showCaiGou: false, //采购单
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "代购订单号",
          field: "dgOrderNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "购买单号",
          field: "buyOrderNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "快递单号",
          field: "courierNumber",
          width: "150",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "代购状态",
          field: "orderTypeShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "跟单客服",
          field: "contactName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "取消状态",
          field: "cancelStatusShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "取消原因",
          field: "cancelReason",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "商品来源",
          field: "platformTypeShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "memberName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "详细地址",
          field: "address",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "归属代理商",
          field: "agentName",
          width: "160",
          sort: 2,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "省",
          field: "provinceName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "市",
          field: "cityName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "区",
          field: "areaName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "拆箱展示",
          field: "chaiXiangService",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "渠道",
          field: "channelName",
          width: "160",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "增值服务",
          field: "checkService",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "购买备注",
          field: "comment",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "创建时间",
          field: "createTime",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款流水号",
          field: "dealNumber",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "代购订单id",
          field: "dgOrderId",
          width: "120",
          sort: 4,
          isShow: false,
          isTotal: false,
          isSort: false,
        },

        {
          name: "国内运费",
          field: "domesticFreight",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "收件人",
          field: "fullName",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "数量",
          field: "itemCount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "原单价",
          field: "itemPrice",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "现单价",
          field: "actualPrice",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "商品规格",
          field: "itemSpecs",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "商品标题",
          field: "itemTitle",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "商品链接",
          field: "itemUrl",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "代购订单类型",
          field: "orderForm",
          width: "120",
          sort: 4,
          isShow: false, //不展示
          isTotal: false,
          isSort: false,
        },
        {
          name: "代购订单状态",
          field: "orderType",
          width: "120",
          sort: 4,
          isShow: false, //不展示
          isTotal: false,
          isSort: false,
        },
        {
          name: "付款方式",
          field: "paymentType",
          width: "120",
          sort: 4,
          isShow: false, //不展示
          isTotal: false,
          isSort: false,
        },
        {
          name: "代购订单类型", //代购订单类型展示值
          field: "orderFormShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "代购订单状态", //代购订单状态展示值
          field: "orderTypeShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款方式", //付款方式展示值
          field: "paymentTypeShow",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "订单备注",
          field: "orderRemarks",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "包装服务",
          field: "packageService",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "付款时间",
          field: "payTime",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "收件电话",
          field: "phone",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "邮政编码",
          field: "postalCode",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "修改单价",
          field: "actualPrice",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "修改原因",
          field: "reasons",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "商品sku",
          field: "skuId",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "总金额(总价)",
          field: "totalAmount",
          width: "120",
          sort: 4,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "IndentOrderList", //页面标识

      writeoffStatusList: [], //核销状态
      orderTypeList: [], //代购状态
      orderFormList: [], //订单类型
      unpackingServiceList: [], //拆箱服务
      currentSelectData: [], //选中的数据
      ids: [], //选中的id数据
      showFuKuan: false,
      PayMoneyData: {
        purchaseOrderNums: "", //代购单号
        totals: "", //合计
        fileNames: [],
        financeAssetaccountId: "", //资金账号id
        orderIds: "", //订单id数组
        payTime: "", //付款时间
      },
      fileListImg: [],
      ImgNames: [],
      zijingList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      memberList: [],

      // 新建跟踪组件的
      showDia1: false,
      documentNumbers: "",
      documentStatus: "",

      // 取消审核
      payCancel: false, //随波=>取消订单(付款成功也可以取消)  随波的就==true
      cancelOrders: false,
      shenHeData: {
        cancelStatus: "",
        orderId: "",
        refuseReason: "",
      },
      cancelOrder_1: false,
      shenHeData_1: {
        dgNums: "",
        cancelIds: [],
        reason: "",
      },
      cancelStatusList: [
        {
          name: "同意取消",
          val: "tong_yi:cancel_status",
        },
        {
          name: "不同意取消",
          val: "bu_tong_yi:cancel_status",
        },
      ],
      showFenPeiKF: false,
      FPKFData: {
        daiGouOrders: "",
        kfId: "",
        orderId: [],
      },
      KfContactList: [],

      cancel_order_reason_list: [],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头

    this.queryData.endCreateTime = tools.getCurrentDate(); //今天的时间;
    this.queryData.startCreateTime = tools.getFlexDate(-10); //10天前的时间

    this.getVipList();
    this.getChaixiangList();
    this.getStatusValList("dg_order_v1.order_type"); //代购状态
    this.getStatusValList("dg_order_v1.order_form"); //订单类型
    this.getAssetaccountList(); //资金账户列表

    this.getData();
  },
  mounted() {},
  activated() {
    // this.getData();
  },
  watch: {},
  methods: {
    // 获取数据
    getKfContactList() {
      let param = {};
      param.pageStart = 1;
      param.pageTotal = 200;
      param.contactType = "gen_dan:contact_type";
      Api.getKfContactList(param).then((res) => {
        //console.log(res.data.result.data);
        if (res.data.status == "success") {
          this.KfContactList = res.data.result.data || [];
        }
      });
    },
    // 提交新建
    confirmAdd(formName) {
      this.showDia1 = false;
      this.getData();
    },
    // 关闭重置弹窗数据
    closeDia1(e) {
      this.showDia1 = e || false;
      this.documentNumbers = "";
    },
    // 选择会员
    huiayu(e, num) {
      if (!e) {
        this.getVipList();
        this.queryData.memberId = "";
        this.queryData.memberName = "";
        return false;
      }
      if (num == 1) {
        this.memberList.forEach((item) => {
          if (item.memberId == e) {
            this.queryData.memberName = item.nickname;
          }
        });
      }
      if (num == 2) {
        this.memberList.forEach((item) => {
          if (item.nickname == e) {
            this.queryData.memberId = item.memberId;
          }
        });
      }
      this.queryBtn_ok();
    },
    // 远程搜索会员
    getMembers(e) {
      if (e.length > 0) {
        this.getVipList(e);
      } else {
        this.getVipList();
      }
    },
    // 远程搜索会员名
    getMembersName(e) {
      if (e.length > 0) {
        this.getVipList(e, "name");
      } else {
        this.getVipList();
      }
    },
    getVipList(e, type) {
      let val = e || "";
      Api.getMemberDownList({
        memberId: type ? "" : val,
        nickname: type == "name" ? val : "",
      }).then((res) => {
        if (res.data.status === "success") {
          this.memberList = res.data.result.data || [];
          // this.total = res.data.result.pageCount || 0;
        }
      });
    },
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);

      // @row-click="rowclick"
    },
    // 获取资金账户列表
    getAssetaccountList() {
      Api.capitalAccountList().then((res) => {
        this.zijingList = res.data.result || [];
      });
    },
    // 收款确认
    confirmPaymen() {
      let fnames = [];
      this.ImgNames.forEach((item) => {
        fnames.push(item.imgName);
      });

      let param = {
        writeOffPic: fnames,
        assetAccountId: this.PayMoneyData.financeAssetaccountId,
        dgOrderIds: this.PayMoneyData.orderIds,
        writeOffTime: this.PayMoneyData.payTime,
      };
      param.sign = this.Tools.getSign(param);
      Api.bmg_collectionDgOrder(param).then((res) => {
        this.$message.success(res.data.message || "付款成功");
        this.showFuKuan = false;
        this.getData();
      });
    },
    //自定义上传
    uploadSectionImg(param) {
      console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let imgName = res.data.result.fileName;
            let objs = {
              imgName: imgName,
              oldName: fileName,
            };
            this.ImgNames.push(objs);
          }
        })
        .catch((err) => {});
    },
    //删除图片
    handleRemove(file, fileListImg) {
      this.ImgNames.forEach((item, index) => {
        if (item.oldName == file.name || item.imgName == file.name) {
          this.ImgNames.splice(index, 1);
          return;
        }
      });
    },
    // 预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 付款
    fuKuan() {
      let cDatas = this.currentSelectData;
      if (cDatas.length == 0) {
        this.$message.warning("请选择一条以上代购单进行付款");
        return false;
      }
      let ids = [];
      let purchaseOrderNumbers = "";
      let totalAmounts = 0;
      for (var i = 0; i < cDatas.length; i++) {
        let item = cDatas[i];
        ids.push(item.dgOrderId);
        purchaseOrderNumbers += item.dgOrderNumber + ",";
        totalAmounts = this.Tools.add(totalAmounts, item.totalAmount);
        if (item.orderType != "dai_fu_kuan:order_type") {
          this.$message.warning("所选代购单有不是待付款状态的单,请重新选择");
          return false;
        }
      }
      console.log("purchaseOrderNumbers");
      console.log(purchaseOrderNumbers);
      if (
        purchaseOrderNumbers.length > 0 &&
        purchaseOrderNumbers[purchaseOrderNumbers.length - 1] == ","
      ) {
        {
          purchaseOrderNumbers = purchaseOrderNumbers.substring(
            0,
            purchaseOrderNumbers.length - 1
          );
        }
      }

      this.showFuKuan = true;
      this.PayMoneyData.purchaseOrderNums = purchaseOrderNumbers;
      this.PayMoneyData.totals = this.Tools.mathToFixed(totalAmounts, 2);
      this.PayMoneyData.orderIds = ids;
      this.getAssetaccountList(); //资金账户列表
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this.judge == false) {
        this.MoreConditions = "更多条件";
      } else {
        this.MoreConditions = "收起条件";
      }
    },

    // 根据字典类型获取字典值下拉列表
    getConfDictValDownList(val) {
      // cancel_order_reason   取消原因

      Api.getConfDictValDownList({ dictType: val }).then((res) => {
        let resultData = res.data.result || [];
        console.log("resultData", resultData);
        if (val == "cancel_order_reason") {
          this.cancel_order_reason_list = resultData;
        }
      });
    },
    // 获取状态展示值
    getStatusValList(status) {
      var dataArr = [];
      // 包裹状态: ==========> package_v1.package_status
      // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
      // 验货单验货状态: ====> bill_inspection_v1.check_status
      // 审批状态:==========> finance_in_record_v1.approve_status
      // 核销状态:==========> finance_in_record_v1.writeoff_status
      // 单据类型:==========> finance_in_record_v1.document_status
      // 打包状态:==========> bill_pack_v1.pack_status
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 代购状态
        if (status == "dg_order_v1.order_type") {
          let dataArr = res.data.result || [];
          this.orderTypeList = dataArr;
        }
        // 订单类型
        if (status == "dg_order_v1.order_form") {
          let dataArr = res.data.result || [];
          this.orderFormList = dataArr;
        }
        // // 拆箱服务
        // if (status == "dg_order_v1.unpacking_service") {
        //   let dataArr = res.data.result || [];
        //   this.unpackingServiceList = dataArr;
        // }
        // 取消原因
        if (status == "cancel_order_reason") {
          let dataArr = res.data.result || [];
          this.cancel_order_reasonLists = dataArr;
        }
      });
    },
    // 获取拆箱服务
    getChaixiangList() {
      Api.getCategoryServiceList({
        directionStatus: "shou:direction_status",
        serviceStatus: "chai_xiang:service_status",
      })
        .then((res) => {
          console.log(res.data.result);
          this.unpackingServiceList = res.data.result || [];
        })
        .catch((err) => {});
    },
    // 关闭付款确认弹窗事件
    closeFuKuan() {
      this.ImgNames = [];
      this.PayMoneyData = {
        purchaseOrderNums: "", //采购单号
        totals: "", //合计
        fileNames: [],
        financeAssetaccountId: "", //资金账号id
        orderIds: "", //订单id数组
        payTime: "", //付款时间
      };
    },
    // 取消订单审核关闭
    closeCancelOrder() {},

    // 取消订单关闭
    closeCancelOrder_1() {
      this.shenHeData_1.cancelIds = [];
      this.shenHeData_1.dgNums = [];
      this.shenHeData_1.reason = "";
    },
    // 分配客服
    closeFPKF() {},

    //
    // 取消订单
    confirmCancelDia_1() {
      if (!this.shenHeData_1.reason) {
        this.$message.error("取消原因不能为空");
        return false;
      }
      let param = {
        dgOrderIds: this.shenHeData_1.cancelIds || [],
        reason: this.shenHeData_1.reason || "",
      };
      param.sign = tools.getSign(param);
      this.$confirm("是否取消所选代购单?", "提示", { type: "warning" })
        .then((res) => {
          Api.bmg_cancelDgOrder(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
            this.cancelOrder_1 = false;
          });
        })
        .catch(() => {});
    },
    // 取消订单审核确认
    confirmCancelDia() {
      if (this.shenHeData.cancelStatus == "bu_tong_yi:cancel_status") {
        if (!this.shenHeData.refuseReason) {
          this.$message.error("请输入拒绝原因");
          return false;
        }
      } else {
        // delete this.shenHeData.refuseReason;
      }
      let param = {
        cancelStatus: this.shenHeData.cancelStatus || "",
        orderId: this.shenHeData.orderId || "",
        refuseReason: this.shenHeData.refuseReason || "",
      };
      param.sign = tools.getSign(param);
      Api.examineCancelDgOrder(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getData();
        this.cancelOrders = false;
      });
    },

    confirmCancelDiaKF() {
      let param = {
        kfId: this.FPKFData.kfId,
        orderIds: this.FPKFData.orderId || [],
      };
      param.sign = this.Tools.getSign(param);
      Api.distributionKf(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.showFenPeiKF = false;
        this.getData();
      });
    },

    // 费项修改
    editFinance() {
      // documentStatus
      if (this.currentSelectData.length > 1) {
        this.$message.error("一次只能修改一条数据哦~");
        return;
      } else if (this.currentSelectData.length <= 0) {
        this.$message.error("您还没有选择数据哦~");
        return;
      }

      // approveStatus: "shen_pi:approve_status"
      let data = this.currentSelectData[0];
      //只有审批中的可以修改费项
      if (data.approveStatus !== "shen_pi:approve_status") {
        this.$message.error("只有审批中的可以修改费项~");
        return;
      }

      //仓库打包单据
      if (data.documentStatus == "bill_storepack_v1:document_status") {
        console.log("仓库打包单据");
        this.$router.push({
          path: "/Finance/modifyFee",
          query: {
            pageType: "newpage",
            documentStatus: data.documentStatus,
            financeInId: data.financeInId,
          },
        });
      } else {
        this.$message.warning("只支持修改仓库打包单哦!");
      }

      // //申请验货单
      // if (data.documentStatus == "bill_inspection_v1:document_status") {
      //   console.log("申请验货单");
      //   Api.getFinanceInChargingInspectionDetail(param).then((res) => {});
      // }
      // //其他单据
      // if (data.documentStatus == "finance_documentstatus_v1:document_status") {
      //   console.log("其他单据");
      // }
      // //拒收单据
      // if (data.documentStatus == "bill_rejection_v1:document_status") {
      //   console.log("拒收单据");

      //   Api.getFinanceInChargingRejectionDetail(param).then((res) => {});
      // }
      // //充值转账单据
      // if (data.documentStatus == "bill_transfer_v1:document_status") {
      //   console.log("充值转账单据");
      // }

      // //包裹异常单据
      // if (data.documentStatus == "bill_package_exception_v1:document_status") {
      //   console.log("包裹异常单据");
      // }
    },
    details() {
      if (this.currentSelectData.length > 1) {
        this.$message.warning("一次只能查看一条代购订单详情");
        return false;
      }
      let id =
        this.currentSelectData.length == 1
          ? this.currentSelectData[0].dgOrderId
          : "";
      this.$router.push({
        path: "/pages/IndentOrder/IndentOrderDetail",
        query: {
          dgOrderId: id,
        },
      });
      //
    },
    // 取消 (已付款的也可以取消,客户:随波)
    cancelBtns() {
      if (this.currentSelectData.length != 1) {
        this.$message.warning("请选择一条需要取消的订单");
        return false;
      }

      if (
        this.currentSelectData[0].cancelStatus == "wei_shen_qing:cancel_status"
      ) {
        this.$message.warning("该代购单未申请取消");
        return false;
      }

      this.shenHeData.orderId = this.currentSelectData[0].dgOrderId;
      this.shenHeData.cancelReason =
        this.currentSelectData[0].cancelReason || "";
      this.cancelOrders = true;

      // examineCancelDgOrder
    },
    // 取消 (待付款的才可以取消)
    cancelBtn() {
      // this.payCancel = true; // 这个后面要根据企业编号来判断
      if (this.payCancel) {
        this.cancelBtns();
        return false;
      }
      if (this.currentSelectData.length < 1) {
        this.$message.warning("请先选择一条以上的数据");
        return false;
      }

      this.getConfDictValDownList("cancel_order_reason");
      let ids = [];
      let dgstr = "";
      for (var i = 0; i < this.currentSelectData.length; i++) {
        let item = this.currentSelectData[i];
        ids.push(item.dgOrderId);
        dgstr += item.dgOrderNumber + ", ";
      }
      this.shenHeData_1.cancelIds = ids;
      this.shenHeData_1.dgNums = dgstr;
      if (ids.length == 0) {
        return false;
      }
      this.cancelOrder_1 = true;
    },
    // 退款
    orderTuiKuan() {
      if (this.currentSelectData.length < 1) {
        this.$message.warning("请先选择一条以上的数据");
        return false;
      }

      let ids = [];
      for (var i = 0; i < this.currentSelectData.length; i++) {
        let item = this.currentSelectData[i];
        ids.push(item.dgOrderId);
      }
      let param = {
        dgOrderIds: ids,
      };
      param.sign = tools.getSign(param);
      console.log(param);
      this.$confirm("是否把已选数据退款到对应会员的余额?", "提示", {
        type: "warning",
      })
        .then((res) => {
          Api.bmg_refundDgOrder(param).then((res) => {
            this.$message.success(res.data.message || "操作成功");
            this.getData();
          });
        })
        .catch(() => {});
    },
    // 新增修改
    addAndmodify(a) {
      let type = a; //0=新增,1=修改
      console.log(type);
      var arr = [];
      // 修改
      if (type == 1) {
        if (this.currentSelectData.length != 1) {
          this.$message.warning("请选择一条代购单进行修改~");
          return false;
        }

        // if (this.currentSelectData[0].orderType == "qu_xiao:order_type") {
        //   this.$message.warning("该订单已经取消,无法修改~");
        //   return false;
        // }
        // if (this.currentSelectData[0].orderType == "dai_ding_gou:order_type") {
        //   this.$message.warning("该订单已经付款,无法修改~");
        //   return false;
        // }
        this.$router.push(
          "/pages/IndentOrder/EditaddIndentOrder?pageType=edit&dgOrderId=" +
            this.currentSelectData[0].dgOrderId
        );
      } else {
        console.log("新增");
        this.$router.push("/pages/IndentOrder/addIndentOrder?pageType=add");
      }
      // 新增
    },
    daigouGenZong() {
      let str = "";
      console.log(this.currentSelectData);
      this.currentSelectData.forEach((item) => {
        str += item.dgOrderNumber + ",";
      });
      if (str.length > 0) {
        str = str.substring(0, str.length - 1);
      }
      this.documentNumbers = str;
      this.documentStatus = "dg_order_v1:document_status"; //代购单单据
      this.showDia1 = true;
    },
    fenPeiKeFu() {
      if (this.currentSelectData.length == 0) {
        this.$message.warning("您还没选择数据~");
        return false;
      }
      let arrs = [];
      this.currentSelectData.forEach((item) => {
        this.FPKFData.daiGouOrders += item.dgOrderNumber + ", ";
        arrs.push(item.dgOrderId);
        //
      });
      this.FPKFData.orderId = arrs;
      this.showFenPeiKF = true;
      this.getKfContactList();
    },
    // 采购
    caigouBtn() {
      // for (var i = 0; i < this.currentSelectData.length; i++) {
      //   arr.push(this.currentSelectData[i].financeInId);
      // }
      // this.ids = arr;
      // let ids = this.ids;
      if (this.currentSelectData.length == 0) {
        this.$message.warning("您还没选择数据~");
        return false;
      }
      if (this.currentSelectData.length > 1) {
        this.$message.warning("一次只能选一条代购单~");
        return false;
      }
      if (this.currentSelectData[0].orderType != "dai_ding_gou:order_type") {
        this.$message.warning("只有待订购状态的才可以采购噢~");
        return false;
      }

      this.$router.push(
        "/pages/IndentOrder/addPurchaseOrder?pageType=caigou&dgOrderId=" +
          this.currentSelectData[0].dgOrderId +
          "&memberId=" +
          this.currentSelectData[0].memberId
      );
      // this.showCaiGou = true;
      // this.caiGouFormData.dgOrderNumber = this.currentSelectData[0].dgOrderNumber;
      // this.caiGouFormData.buyNumber = this.currentSelectData[0].buyOrderNumber;
    },
    // 新建采购单
    createCaigou() {
      Api.bmg_createPurchaseOrder({
        buyNumber: this.caiGouFormData.buyNumber,
        dgOrderNumber: this.caiGouFormData.dgOrderNumber,
      }).then((res) => {
        this.$message.success(res.data.message || "新建成功");
        this.showCaiGou = false;

        this.getData();
      });
    },
    // 关闭新建采购单弹出
    closeCaiGou() {
      this.caiGouFormData.buyNumber = "";
      this.caiGouFormData.dgOrderNumber = "";
    },

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      ////console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 10) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let that = this;

      //   创建时间
      let endCreateTime = "";
      let startCreateTime = "";
      if (that.queryData.endCreateTime) {
        endCreateTime = that.queryData.endCreateTime + " 23:59:59";
      }
      if (that.queryData.startCreateTime) {
        startCreateTime = that.queryData.startCreateTime + " 00:00:00";
      }
      //   付款时间;
      let endPayTime = "";
      let startPayTime = "";
      if (this.queryData.endPayTime) {
        endPayTime = this.queryData.endPayTime + " " + "23:59:59";
      }
      if (this.queryData.startPayTime) {
        startPayTime = this.queryData.startPayTime + " " + "00:00:00";
      }

      let formData = {
        agentId: "", //归属代理商id
        courierNumber: that.queryData.courierNumber, //快递单号
        dgOrderNumber: that.queryData.dgOrderNumber, //代购订单号
        startCreateTime: startCreateTime, //起始创建时间
        endCreateTime: endCreateTime, //结束创建时间
        startPayTime: startPayTime, //起始付款时间
        endPayTime: endPayTime, //结束付款时间
        isSensitive: that.queryData.isSensitive, //是否敏感 1=敏感 0=非敏感
        memberId: that.queryData.memberId, //会员号
        memberName: that.queryData.memberName, //会员名称
        orderForm: that.queryData.orderForm, //订单类型
        orderType: that.queryData.orderType, //代购状态
        unpackingService: that.queryData.unpackingService, //拆箱服务
        pageStart: that.pageNum, //页码
        pageTotal: that.size, //每页条数
      };

      Api.bmg_getDgOrderList(formData).then((res) => {
        if (res.data.status == "success") {
          that.className = res.data.result.className || "";
          that.tableData = res.data.result.data || [];
          that.total = res.data.result.pageCount || 0;

          this.fetTableHeight();
        }
      });

      // if (that.queryData.endPayTime) {
      //   that.queryData.endPayTime = that.queryData.endPayTime.slice(0, 10);
      // }
      // if (that.queryData.startPayTime) {
      //   that.queryData.startPayTime = that.queryData.startPayTime.slice(0, 10);
      // }
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        agentId: "", //归属代理商id
        courierNumber: "", //快递单号
        dgOrderNumber: "", //代购订单号
        startCreateTime: "", //起始创建时间
        endCreateTime: "", //结束创建时间
        startPayTime: "", //起始付款时间
        endPayTime: "", //结束付款时间
        isSensitive: "", //是否敏感 1=敏感 0=非敏感
        memberId: "", //会员号
        memberName: "", //会员名称
        orderForm: "", //订单类型
        orderType: "", //代购状态
        unpackingService: "", //拆箱服务
      };
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      ////console.log(e);
      this.currentSelectData = e || [];
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  // return prev + curr;
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
    // 上一页
    handlePrevPage() {
      this.pageNum = this.pageNum - 1 || 1;
      this.getData();
    },
    // 下一页
    handleNextPage() {
      this.pageNum = this.pageNum + 1 || 1;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.HeadInputbox .item_right .singleinp {
  ::v-deep {
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}

.btnsColor {
  background-color: #f0a261;
  color: #fff;
}
</style>
